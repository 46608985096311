<template>
    <div class="overflow-hidden">
        <Quote title="Foydalanuvchining modullari"/>
        <GoBack next="Foydalanuvchining modullari" class="mb-4"/>
        <div ref="top" class="row justify-content-center">
            <div v-if="!getStudentCourses.length" class="col-11 overflow-hidden">
                <h4 class="text-center p-2">
                    Ushbu foydalanivchining modullari mavjud emas
                </h4>
            </div>
            <div v-else class="col-11 overflow-hidden">
                <h4 class="text-center p-2">
                    {{ getStudentCourses[0].user.givenName }} {{ getStudentCourses[0].user.familyName }}ning modullari:
                </h4>
                <b-table-simple>
                    <b-thead class="p-2">
                        <b-tr style="background-color: #E5E5E5">
                            <th class="d-none d-md-table-cell">Id</th>
                            <th class="d-none d-md-table-cell">Kurs nomi</th>
                            <th>Modul nomi</th>
                            <th>Boshlanish sanasi</th>
                            <th>To'lagan summasi</th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <tr
                            class="pl-3 pt-3 pb-3"
                            v-for="course in getStudentCourses"
                            :key="course.id"
                        >
                            <td data-label="User Id" class="d-none d-md-table-cell">
                                <span class="texts">{{ getStudentCourses[0].user.id }}</span>
                            </td>
                            <td data-label="Second name" class="d-none d-md-table-cell">
                                <span class="texts">{{ course.course.module.name }}</span>
                            </td>
                            <td data-label="Second name" class="d-md-table-cell">
                                <span class="texts">{{ course.course.name }}</span>
                            </td>
                            <td data-label="Second name" class="d-md-table-cell">
                                <span class="texts">{{ course.course.startMonth.name }}</span>
                            </td>
                            <td
                                v-if="!course.course.boughtCourses.some(el => el.isPayed === true)"
                                data-label="Second name"
                                class="d-md-table-cell"
                            >
                                <span class="texts">{{ 0 }}</span>
                            </td>
                            <td
                                v-else-if="course.course.boughtCourses.some(el => el.isPayed === true)"
                                data-label="Second name"
                                class="d-md-table-cell"
                            >
                                <span class="texts">{{ numberWithSpaces(course.course.price) }}</span>
                            </td>
                        </tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </div>
    </div>
</template>

<script>

import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "UsersListPage",
    components: {
        Quote,
        GoBack
    },
    computed: {
        ...mapGetters(['getStudentCourses'])
    },
    methods: {
        ...mapActions(['fetchStudentCourses']),
        numberWithSpaces(price) {
            if (price === 0) {
                return 0;
            } else {
                return (price / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        },
    },
    mounted() {
        this.show = true
        // this.fetchOneUser(this.$route.params.id)
        this.fetchStudentCourses(this.$route.params.id)
        this.show = false
    }
}
</script>

<style scoped>
</style>
